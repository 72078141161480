import React from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { FHNextImage, ImageLoader } from "../FHNextImage";
import whichLogo from "../../assests/uk-holiday-parks-which-logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    marginTop: 32,
    "& >span": {
      display: "flex",
      justifyContent: "center",
    },
  },
  text: {
    fontSize: 18,
    lineHeight: "24px",
    paddingTop: 16,
  },
}));

type WhichSectionProps = {
  imageLoader?: ImageLoader;
};

export const WhichSection: React.FC<WhichSectionProps> = ({
  imageLoader,
}: React.PropsWithChildren<WhichSectionProps>) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FHNextImage
        src={whichLogo}
        alt="UK HOLIDAY PARKS"
        width={115}
        height={91}
        loader={imageLoader}
        isStaticImg
      />
      <Typography className={classes.text}>
        We’re a <strong>Which?</strong>
        <br /> Recommended Provider.
      </Typography>
    </div>
  );
};
